import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CommandeList from '../../core/sysext/Boutique/containers/Commande/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const PageCommandes: FC<PageProps & { pageContext: { tab?: number } }> =
  props => {
    const {
      location,
      pageContext: { tab },
    } = props;

    return (
      <Backoffice location={location}>
        <CommandeList
          {...props}
          itemPathnamePrefix="/boutique/commandes/"
          tabIndex={tab ?? 3}
        />
      </Backoffice>
    );
  };

export default PageCommandes;
